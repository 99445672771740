import React from "react"

import Layout from '../../components/layout'
import Head from '../../components/head'
import QbsTabbed from "../../components/qbs_tabbed"

const Qbs = () => {

    return (
        <Layout>
            <Head
                title="QBs"
                pageDescription="Historical QB rankings adjusted for era"  
            />
            <QbsTabbed
                activeIndex={1}
            />
        </Layout>
    )
}

export default Qbs

